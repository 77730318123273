.headerImage{
    padding-top:100px;
    background-size:cover;
    background-position-y:50%;
}
.adheaderImage{
    background-size:cover;
    background-position-y:50%;
}
.dimblurBackground{
    backdrop-filter: blur(3px);
    background:rgba(0,0,0,0.6)
}
ion-toolbar.sc-ion-searchbar-ios-h, ion-toolbar .sc-ion-searchbar-ios-h{
    padding-top:15px !important;
}
.profile_link_logo{
    font-size:2em;
    padding:10px
}


.grid {
   display: grid;
   grid-gap: 10px;
   grid-template-columns: repeat(auto-fill, minmax(350px,1fr));
}
 
 ion-list{
    background:none!important;
 }
 .applyForSession{
    background-image: url('../../public/assets/applyforsession.jpg');
 }

 .floatbr{
    position:fixed;
    right:0px; bottom:0px; display:inline-block;

 }

.fullscreen .react-transform-component,.fullscreen .react-transform-wrapper{
    height:100% !important;
    width:100% !important
 }
 .float-top-left{
    position:fixed;
    top:0px; 
    left: 0px;
    z-index:999
 }

ion-toast.errorToast {
    --background: #eee25d;
    --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
    --color: #000;
  }
      
  ion-toast.errorToast::part(message) {
    font-size: 1.5em; text-align: center;
  }
  .fullscreenModal{
    --width:100% !important;
    --height:100% !important;
    width:100% !important;
    height: 100% !important
  }
.twitter-share iframe{
    background: transparent;
    background-color: transparent;
    border-radius:999px;;
  }