
.welcomepage_bg{
   
   background:linear-gradient(107deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.7) 100%),url('../../public/assets/bg.jpg');
   background-size:cover ;
   background-position: 50% 50%;
   width: 100%;
   height: 100%;
   position:fixed;
   z-index:-1;

}